import React, {Component} from 'react'
import SectionPage        from './components/section-page'
import store              from './store'
import marked             from 'marked'
import faq from '../images/section-page/faq.png'
import { resetMetadata } from './actions/metas'

export default class FAQContainer extends Component {
    componentWillMount(){
        resetMetadata()
    }
    getContent(){
        return store.getState().pages.byId.faq.content
    }
    render(){
        return (
            <SectionPage className="faq-page"
                         headerImageUrl={faq}
                         html={marked(this.getContent())} />
        )
    }
}
