import React, { Component } from "react";
import _ from "lodash";
import cn from "classnames";
import PropTypes from "prop-types";
import { allYears as yrs, minYear, maxYear } from "./stubs/gen-years";
import BubbleInfo from "./components/bubble-info";
import YearsDisplayHorizontal from "./components/years-display-horizontal";
import TimelineEvents from "./components/timelineEvents";
import {clearTimelineControls} from './components/timelineControllers'
import TourZO from "./components/tour-zo";
import TourYear from "./components/tours/tourYear";
import LyricsModal from "./components/lyrics-modal";
import PhotoModal from "./components/photo-modal";
import Toggles from "./components/timeline-toggles";
import { Pages } from "./components/timeline-pages";
import { 
  sortedItems,
  getAlbumById, 
  getTimelineTopHalfContentByYear,
  getTimelineZOEventById,
  getEventsByYear,
  getTracksByYear,
  getAlbumsFromCabinet
} from "./drawer-items";
import Easing from "./util/easing";
import { monthNames, monthName } from "./util/months";
import clamp from "./util/clamp";
import { scale, timelineScale } from "./scale";
import { setCurrentDate ,setCurrentTrack, getCurrentTrack} from "./currentDate";
import { albumIsLockedByID, filmIsLockedByID } from "./user";
import {updateToggle} from './actions/toggles'
import store from "./store";



import tile0 from "../images/timeline-years/tile0.jpg";
import tile1 from "../images/timeline-years/tile1.jpg";
import tile2 from "../images/timeline-years/tile2.jpg";
import tile3 from "../images/timeline-years/tile3.jpg";

import label from "../images/timeline/NYA_Button_Release_Solo.png"
import { albums } from "./stubs/gen-albums";
import { connect } from "react-redux";
import SplashScreen from "./splash-screen";

const tiles = [tile0, tile1, tile2, tile3];

let allYears = yrs.slice(); /* copy so we can add spacers */
allYears.unshift({ spacer: true, id: -1 });
allYears.push({ spacer: true, id: -2 });

const yearCount = allYears.length;
const maxYearIndex = allYears.length - 2;
const DIFF_IN_DAYS = 15;

const pagePercentage =
  (1 / (yearCount - 1)) * 100; /* percentage width of a single page */

function getPercentForIndex(idx) {
  return (idx / (yearCount - 1)) * 100;
}

let pageHeightToWidth = 720 / 980;

/* we don't actually view from 0 to 100%, it is inset a bit
 * to handle the edge cases at the beginning and end of the timeline
 * */
function getMinPercent() {
  return pagePercentage * 2;
}

/* the max percentage position that keeps the right edge
   of the last page flush to right edge of the viewport */
function getMaxPercent() {
  let { width, pageW } = Pages.measure(allYears, pageHeightToWidth);

  let pct = getPercentForIndex(maxYearIndex);
  let pw = width / pageW - 2;

  return pct - pw * pagePercentage;
}

//percentage value in the timeline for a given year
function getPercent(year) {
  //handle the edge cases of the first and last pages
  if (year === minYear) return getMinPercent();
  if (year === maxYear) return getMaxPercent();

  let index = _.findIndex(allYears, y => {
    return y.year === year;
  });
  let pct = getPercentForIndex(index);
  return pct;
}

//percentage to adjust the initial position when we link in
function getCenteringAdjustment(year, monthName = "jan") {
  if (year === minYear) return 0;

  let monthIdx = monthNames.indexOf(monthName);
  let monthAdjust = (pagePercentage / 12) * monthIdx;

  let count = Pages.getVisiblePageCount(allYears, pageHeightToWidth);
  let margin = (count - 1) / 2; /* amount of padding to left of item */
  let leftSpace = margin * pagePercentage;

  let fudge = -pagePercentage * 0.5; /* fudge by half a page to uncenter. */
  fudge += monthAdjust; /* adjust over to the current month */
  fudge += pagePercentage / 24; /* adjust to center over the current month */

  return pagePercentage - leftSpace + fudge;
}

function currentYear(pct) {
  return allYears[currentIndex(pct)];
}
function currentIndex(pct) {
  let len = allYears.length - 1;
  return Math.floor(len * (pct / 100));
}

function currentMonth(pct) {
  let len = allYears.length - 1;
  let idx = len * (pct / 100);
  let rem = idx - Math.floor(idx);
  let num = Math.floor(rem * 12);
  return monthNames[num];
}

function calcAlbumPosition(album) {
  let { month }=   album.hasOwnProperty('releaseAlbum')?album.releaseSticker ? album.timelineReleaseDate : album.releaseDate 
                 : album.hasOwnProperty('completionAlbum')? album.completionDate : album.releaseDate ;
  let result = (month / 12) * 100;
  return result;
}

function getMaxAlbumPosition() {
  let pageWidth = 980;
  let albumWidth = 162 * 1.5 + 20;
  let width = pageWidth - albumWidth;
  let percent = width / pageWidth;

  return percent * 100;
}

function albumPositioner(seen, isPrev) {
  return function(album) {
    let pos = Math.floor(calcAlbumPosition(album) / 5) * 5;

    if (isPrev) pos -= 100;
    while (seen[pos]) {
      pos += 5;
    }
    seen[pos] = true;
    return pos;
  };
}

function getAlbumDisplay(album={}, completion) {
  let display = album.hasOwnProperty('releaseAlbum') ? album.releaseSticker ? `block`: `none` 
                : album.hasOwnProperty('completionAlbum') ? completion ? 'block' : 'none'
                : completion ? (album.completionDateExist ? 'none':'block') : 'block'

    //change this on redux inside albums 
    // hide empty releasedate albums from TL 
  if(!completion && album.emptyReleaseDate) display = 'none';

  return display;
}

/* we need to keep the album within the page with enough room on the
   right to show the whole album and its drop shadow. can't overlap
   the page to the right due to stacking order issues, so we push
   items that /would/ overlap on the right into the next page and
   position them appropiately */
let albumTable = { "11": {}, "10": {}, "01": {}, "00": {} };
function getAlbumsDisplayedByYear(year, original, additional, completion) {
  let key = (original ? "1" : "0") + (additional ? "1" : "0"); 
  if (!albumTable[key][year]) {
    let prev = sortedItems(getTimelineTopHalfContentByYear(year - 1) || []);
    let curr = sortedItems(getTimelineTopHalfContentByYear(year) || []);


    prev = prev.filter(item => {
      // here are timeline filters 
      if (item.type !== "album"     ||
      original && item.original     ||
      additional && item.additional ||
      item.bootLeg                  ) return true;
      return false;
    });


    curr = curr.filter(item => {
      if (item.type !== "album"     ||
      original && item.original     ||
      additional && item.additional ||
      item.bootLeg                  ) return true;
      return false;
    });

    let seen = {};
    let positionPrev = albumPositioner(seen, true);
    let positionCurr = albumPositioner(seen);

    prev = _.map(prev, album => ({ album, position: positionPrev(album) }));
    curr = _.map(curr, album => ({ album, position: positionCurr(album) }));

    let maxCurrPosition = getMaxAlbumPosition();
    let maxPrevPosition = maxCurrPosition - 100;

    prev = _.filter(prev, ({ position }) => position > maxPrevPosition);
    curr = _.filter(curr, ({ position }) => position < maxCurrPosition);

    let items = prev.concat(curr);

    /* calculates the amount other albums overlap the given album
     * so that we know where to position the info bubble */
    function coveringAmount(index) {
      let albumWidth = 162;
      let pageWidth = 720;
      let stepSize = 0.05 * pageWidth;

      let amount = stepSize;
      let position = items[index].position + 5;
      let curr = seen[position];

      while (amount < albumWidth) {
        if (curr) {
          return (amount / albumWidth) * 100 + 5;
        }
        position += 5;
        curr = seen[position];
        amount += stepSize;
      }

      let width = Math.min(albumWidth, amount);
      return (width / albumWidth) * 100;
    }
    let covering = items.map((_, index) => coveringAmount(index));

    albumTable[key][year] = { items, covering };
  }

  albumTable[key][year].items.forEach((e,index)=>{
    let currentAlbum     = e;
    let previousAlbum    = albumTable[key][year].items[index-1] || {}
    const { album: currAlbum , position:currPosition} = currentAlbum;
    const { album: prevAlbum = false ,position:prevPosition } = previousAlbum;

    let position = currPosition;
    //compare date of both if the same or less than [DIFF_IN_DAYS] days - add extra position
    //here it keeps adding the espace to the position 
    if(currAlbum.releaseDate && prevAlbum.releaseDate){
      const {day:currDay} = currAlbum.releaseDate;
      const {day:prevDay} = prevAlbum.releaseDate;
      if((currDay === prevDay) || ((currDay-prevDay) < DIFF_IN_DAYS)){ // OR THE DIFF BETWEEN THEM IS LESS THAN [DIFF_IN_DAYS]         
        position = (currPosition+5)
        albumTable[key][year].items[index-1].album.position = (prevPosition+10)
      }
    }
    albumTable[key][year].items[index].album.position = position
    albumTable[key][year].items[index].album.positionChanged = true 
})
  return albumTable[key][year];
}

var trackBins = {};
function getBinnedTracksForYear(year) {
  if (trackBins[year]) return trackBins[year];

  let tracks = getTracksByYear(year) || [];

  tracks = tracks.filter(t => !t.listOnly && !t.hiddenTrack);

  let months = [[], [], [], [], [], [], [], [], [], [], [], []];
  tracks.forEach(t => {
    let { month } = t.releaseDate;
    months[month - 1].push(t);
  });

  months = months.map((tracks, idx) => {
    let half_weeks = [[], [], [], [], [], [], [], []];
    tracks.forEach(track => {
      let bin = Math.min(7, Math.floor(track.releaseDate.day / 3.5));
      half_weeks[bin].push(track);
    });

    let month = monthNames[idx];
    return half_weeks.map((tracks, idx) => {
      return { tracks, date: { year, month, day: idx * 4 } };
    });
  });

  trackBins[year] = _.flatten(months);

  return trackBins[year];
}

class Dots extends Component {
  shouldComponentUpdate(newProps) {
    return newProps.year !== this.props.year;
  }
  render() {
    let { year } = this.props;
    let columns = getBinnedTracksForYear(year);

    let toDot = (_, idx) => {
      return <div className="dot" key={idx} />;
    };

    let toCol = (col, idx) => {
      let { tracks, date } = col;
      let { year, month, day } = date;
      return (
        <div
          className="track-column"
          key={idx}
          onClick={this.props.onClick}
          data-year={year}
          data-month={month}
          data-day={day}
        >
          {tracks.map(toDot)}
        </div>
      );
    };

    return <div className="column-container">{columns.map(toCol)}</div>;
  }
}

class TrackDots extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseUp = this.mouseUp.bind(this);

    this.state = { mouse: false };
  }
  onClick(e) {
    if (this.didDrag) return;

    let el = e.target,
      year = parseInt(el.getAttribute("data-year"), 10),
      month = el.getAttribute("data-month"),
      day = parseInt(el.getAttribute("data-day"), 10);

    this.props.onClick({ year, month, day });
  }
  mouseEnter(e) {
    this.rect = this.refs.container.getBoundingClientRect();
  }
  mouseDown(e) {
    this.mouseIsDown = true;
    this.didDrag = false;
  }
  mouseMove(e) {
    if (this.mouseIsDown) {
      this.didDrag = true;
      this.rect = null; //need to update rect during drag.
    }

    if (!this.rect) this.rect = this.refs.container.getBoundingClientRect();

    /* need to translate the coord through both the global scaling and the TL page scaling */

    let iscale = (1 / timelineScale()) * (1 / scale());

    let x = e.clientX;
    let y = e.clientY;
    let left = Math.floor((x - this.rect.left) * iscale) + "px";
    let top = Math.floor((y - this.rect.top) * iscale) + "px";
    this.setState({ mouse: { left, top } });
  }
  mouseUp(e) {
    this.mouseIsDown = false;
  }
  mouseLeave(e) {
    this.setState({ mouse: false });
  }
  render() {
    let { year } = this.props;
    let { mouse } = this.state;

    return (
      <div
        className="tracks-container"
        ref="container"
        onMouseEnter={this.mouseEnter}
        onMouseDown={this.mouseDown}
        onMouseMove={this.mouseMove}
        onMouseUp={this.mouseUp}
        onMouseLeave={this.mouseLeave}
      >
        {mouse && (
          <div style={mouse} className="magnifier">
            <div className="cursor-image" />
          </div>
        )}
        <Dots year={year} onClick={this.onClick} />
      </div>
    );
  }
}

class TimelineYearsContainer extends Component {
  constructor(props, b) {
    super(props, b);

    let { query } = props.location;
    let trackFromDrawer = getCurrentTrack()
    let position = null,
      year = null,
      album = null;

    year = _.has(query, "year") ? parseInt(query.year, 10) : minYear;
    let month = _.has(query, "month") ? query.month : "jul";

    if (month.match(/\d+/)) month = monthName(parseInt(month, 10));
    
    if(trackFromDrawer){
        setCurrentTrack(trackFromDrawer)
    }else{
      setCurrentDate({ year, month });
    }

    if (!_.isFinite(year)) year = minYear;
    position = getPercent(year) + getCenteringAdjustment(year, month);
    year = { year };

    let min = getMinPercent();
    let max = getMaxPercent();

    if (_.has(query, "position")) {
      let pos = parseFloat(query.position);
      if (_.isFinite(pos)) {
        position = pos;
      }
    }

    position = Math.min(Math.max(position, min), max);

    if (!year) {
      year = currentYear(position);
    }

    let selectedEventId = null,
      photoModalState = null;

    if (_.has(query, "photo") && _.has(query, "event")) {
      selectedEventId = query.event;

      let event = getTimelineZOEventById(query.event);
      let collection = event.photos;
      let current = _.find(collection, { id: query.photo });

      photoModalState = { current, collection };
    }

    let selectedAlbumId = null;

    if (_.has(query, "album") || _.has(query, "currAlbum")) {
      selectedAlbumId = query.album || query.currAlbum;
    }

    if (_.has(query, "position")) {
      let pos = parseFloat(query.position);
      if (_.isFinite(pos)) position = pos;
    }

    let roomOnRight = true;

    this.state = {
      position,
      year,
      album,
      photoModalState,
      selectedEventId,
      selectedAlbumId,
      roomOnRight,
    };

    this.onScroll = this.onScroll.bind(this);
    this.clickAlbum = this.clickAlbum.bind(this);
    this.hoverAlbum = this.hoverAlbum.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.zoomToDate = this.zoomToDate.bind(this);
    this.getPageContents = this.getPageContents.bind(this);
    this.scrollByPagePercentage = this.scrollByPagePercentage.bind(this);
    this.closeAlbum = this.closeAlbum.bind(this);
    this.clickViewAlbum = this.clickViewAlbum.bind(this);
    this.clickShowTracks = this.clickShowTracks.bind(this);
    this.hideBubbleAndTracks = this.hideBubbleAndTracks.bind(this);
    this.onYearClick = this.onYearClick.bind(this);
    this.updatePositionUrl = this.updatePositionUrl.bind(this);
    this.updateYearUrl = this.updateYearUrl.bind(this);
    this.clickOnEvent = this.clickOnEvent.bind(this);
    this.showEventModal = this.showEventModal.bind(this);
    this.hideEventModal = this.hideEventModal.bind(this);
    this.showVideoModal = this.showVideoModal.bind(this);
    this.showPhotoModal = this.showPhotoModal.bind(this);
    this.photoModalOnUpdate = this.photoModalOnUpdate.bind(this);
    this.hidePhotoModal = this.hidePhotoModal.bind(this);
    this.mouseEnterItem = this.mouseEnterItem.bind(this);
    this.mouseLeaveItem = this.mouseLeaveItem.bind(this);
    this.animateTo = this.animateTo.bind(this);
    this.toggleUpdate = this.toggleUpdate.bind(this)
  }
  mouseEnterItem() {
    this.setState({ hovering: true });
  }
  mouseLeaveItem() {
    this.setState({ hovering: false });
  }

  componentWillUpdate(newprops) {
    let state = {};
    let prev = this.props.location.query;
    let { query } = newprops.location;
    let { photo } = query;

    if (photo !== prev.photo) {
      let photoModalState = null;
      if (photo) {
        let event = getTimelineZOEventById(query.event);
        let collection = event.photos;
        let current = _.find(collection, { id: query.photo });

        photoModalState = { current, collection };
      }
      state.photoModalState = photoModalState;
    }

    if (_.has(query, "year") && query.year !== prev.year) {
      let { year, month } = query;
      year = parseInt(year, 10);
      month = month || "jul";
      if (month.match(/\d+/)) month = monthName(parseInt(month, 10));
      let position = getPercent(year) + getCenteringAdjustment(year, month);
      state.position = position;
    }

    if (_.has(query, "currAlbum") && query.currAlbum !== prev.currAlbum) {
      state.selectedEventId = null;
      state.selectedAlbumId = query.currAlbum;
    }

    if (_.has(query, "position") && query.position !== prev.position) {
      let pos = parseFloat(query.position);
      if (_.isFinite(pos)) {
        state.position = pos;
      }
    }

    if (Object.keys(state).length) this.setState(state);
  }
  onYearClick(year, onDone) {
    this.jumpToDate({ year }, onDone);
  }
  jumpToDate({ year }, onDone) {
    let position = getPercent(year) + getCenteringAdjustment(year, "jul");
    this.animateTo(position,30, onDone);
    /* this.onScroll(position)*/
  }
  animateTo(newPosition,fpsNumber, onDone=()=>{}){ 
    clearInterval(window.interval)
    let {position} = this.state
    let delta      = newPosition - position
    let duration   = Math.abs(delta) < pagePercentage ? 500 : 1000
    let fps        = fpsNumber
    let frame      = 1000 / fps
    let frameCount = duration / frame
    let step       = 1.0/ frameCount

    let current      = 0
    let currentFrame = 0

    let interval = setInterval(()=>{
        currentFrame++
        current += step
        let pos = (Easing.easeInOutQuad(current) * delta) + position
        this.onScroll(pos)
        if (currentFrame >= frameCount)  {
            clearInterval(interval)
            this.onScroll(newPosition)
            this.updatePositionUrl()
            onDone()
        }
    }, frame)
}
  clickViewAlbum(e) {
    e.preventDefault();
    e.stopPropagation();
    let id = e.target.getAttribute("data-id");
    let album= getAlbumById(id) 
    if(album && album.hasOwnProperty('isAlbum')){
      setCurrentTrack(album)
    }
    
    let { router } = this.context;
    if (id.toLowerCase()[0] === "f") {
      router.push(router.createLocation(`/film?id=${id}`));
    } else {
      router.push(router.createLocation(`/album?id=${id}`));
    }
  }
  clickShowTracks(e) {
    e.preventDefault();
    e.stopPropagation();

    let id = e.target.getAttribute("data-id");
    let album = getAlbumById(id);
    let track = album.tracksForTimeline[0];
    let tid = track.id;

    let { router } = this.context;

    router.push(
      router.createLocation(
        `/timeline-months?viewTrack=${tid}&hl=${id}&prevTrack=${tid}`
      )
    );
  }
  roomOnRight(el) {
    let windowWidth = window.innerWidth;
    let rect = el.getBoundingClientRect();
    let { left, width } = rect;
    let remaining = windowWidth - (left + width);
    let slack = windowWidth / 5; //TODO: calculate this appropriately

    return remaining > slack;
  }
  hoverAlbum(e) {
    if (this.state.selectedAlbumId) return;

    this.clickAlbum(e);
  }
  clickAlbum(e) {

    clearTimelineControls()
 
    let el = e.target;
    let id = el.getAttribute("data-id");

    if (!id) return;

    let album= getAlbumById(id) 
    if(album && album.hasOwnProperty('isAlbum')){
      setCurrentTrack(album)
    }
    let roomOnRight = this.roomOnRight(el);

    let { selectedAlbumId } = this.state;

    if (id === selectedAlbumId) selectedAlbumId = null;
    else selectedAlbumId = id;

    this.setState({ selectedAlbumId, roomOnRight, selectedEventId: null });
  }
  updateQuery(newQuery, push = false) {
    let { router } = this.context;
    let pathname = this.props.location.pathname;
    let query = _.extend({}, this.props.location.query);

    Object.keys(newQuery).forEach(k => {
      if (newQuery[k] === null) delete query[k];
      else query[k] = newQuery[k];
    });

    let location = router.createLocation({ pathname, query });
    delete location.search;

    if (push) router.push(location);
    else router.replace(location);
  }

  updatePositionUrl() {
    let { router } = this.context;
    let pathname = this.props.location.pathname;
    let { position } = this.state;
  
    let query = { position };
    let currDate = this.getCenteredYearAndMonth();
    let positionIndexMonth = new Date(Date.parse(currDate.month +" 1, 2012")).getMonth()+1
   
    let contentByYear= getAlbumsFromCabinet(currDate.year) 

    let  monthInAlbums = contentByYear.map(albums=>albums.releaseDate.month)
    
    let monthIndex  = monthInAlbums.length>0
    ? monthInAlbums.reduce(function(prev, curr) {
      return (Math.abs(curr- positionIndexMonth) < Math.abs(prev- positionIndexMonth) ? curr: prev)}) 
    :0

    let albumIndex = monthInAlbums.indexOf(monthIndex)
    let centeredAlbum = contentByYear[albumIndex]

    if(centeredAlbum  && centeredAlbum.hasOwnProperty('isAlbum')){
      setCurrentTrack(centeredAlbum)
    }else{
      setCurrentDate(currDate);
    }

    let location = router.createLocation({ pathname, query });
    //query parameters are duplicated if this is not deleted for some reason
    delete location.search;

    router.replace(location);
  }

  updateYearUrl() {
    let hh = this.context.router;
    let pathname = this.props.location.pathname;
    let query = this.getCenteredYearAndMonth();
    setCurrentDate(query);

    let location = hh.createLocation({ pathname, query });
    //query parameters are duplicated if this is not deleted for some reason
    delete location.search;

    hh.replace(location);
  }
  onScroll(percentage) {
    let min = getMinPercent();
    let max = getMaxPercent();
    let position = Math.min(Math.max(percentage, min), max);

    if (position === this.state.position) return;

    let year = currentYear(position);

    this.setState({ position, year });
  }
  showEventModal(event) {
    this.setState({ eventForModal: event });
  }
  hideEventModal() {
    this.setState({ eventForModal: null });
  }
  showVideoModal(video) {
    let { router } = this.context;
    router.push(router.createLocation(`/video?id=${video._id}`));
  }
  showPhotoModal(event) {
    let { photos } = event;
    let current = photos[0];
    this.updateQuery({ photo: current.id, event: event.id }, true);
  }
  photoModalOnUpdate(photo) {
    this.updateQuery({ photo: photo.id });
  }
  hidePhotoModal() {
    this.context.router.goBack();
  }
  clickOnEvent(id, makeLeftOffset, makeRightOffset) {
    let { position } = this.state;
    // if (makeLeftOffset !== 0) {
    //   this.animateTo(position - 0.5,30);
    // }

    // if (makeRightOffset !== 0) {
    //   this.animateTo(position + 0.5,30);
    // }
    this.setState({ selectedAlbumId: null, selectedEventId: id });
  }
  closeAlbum() {
    this.setState({ selectedAlbumId: null });
  }
  hideBubbleAndTracks() {
    this.setState({ selectedAlbumId: null, selectedEventId: null });
  }
  getPageContents(pageProps) {
    let props = pageProps;
    const {
      toggles: {
        original = false,
        bootleg: bootlegStatus = false,
        completion = false,
        additional = false,
      },
    } = this.props;
    let { year } = props.item;    
    let content = getAlbumsDisplayedByYear(
      year,
      original,
      additional,
      completion
    );
    let { selectedAlbumId, selectedEventId, roomOnRight } = this.state;


    let showInfo = this.clickAlbum;
    let side = roomOnRight ? "right" : "left";

    let sorted, covering;
    if (content) {
      //Hide from timeline albums 
      content.items = content.items.filter(e=> {
        if ("hideFromTimeLine" in e.album) {
          if (e.album.hideFromTimeLine == false) {
            return e;
          }
        }else{
            return e;
        }       
      });
      sorted = content.items.map(({ album }) => album);

      // positions = content.items.map(({ position }) => position);
      covering = content.covering;
    }

    let backgroundImage = `url('${tiles[year % 4]}')`;

    return (
      <div className="year" style={{ backgroundImage }}>
        <div className="year-label">{year}</div>
        <TourZO
          year={year}
          selectedEventId={selectedEventId}
          onClick={this.clickOnEvent}
          onClose={this.hideBubbleAndTracks}
          showMoreInfo={this.showEventModal}
          showPhotos={this.showPhotoModal}
        />
        <TourYear
          year={year}
          selectedEventId={selectedEventId}
          onClick={this.clickOnEvent}
          onClose={this.hideBubbleAndTracks}
          showMoreInfo={this.showEventModal}
          showPhotos={this.showPhotoModal}
          context={this.context}
        />
        {content
          ? sorted.map((album, idx) => {
              let left = album.position + "%";
              let cover = covering[idx];

              let selected = album.id === selectedAlbumId;

              let { unreleased, bootLeg } = album;
              
              let disabled = false;



              if (album.isFilm) {
                disabled = filmIsLockedByID(album.id);
              } else if (album.isAlbum) {
                disabled = albumIsLockedByID(album.id);
              }
              let releaseLabel = album.releaseSticker ? label : ''
              let released = album.hasOwnProperty('releaseAlbum') ? album.releaseSticker ? 'released' : ' hidden' :'';
              let bootLegItem= bootLeg !== undefined && bootLeg? bootLeg !== bootlegStatus ? ' hidden' : ' bootLegItem' : false
              let display = getAlbumDisplay(album, completion);
               
              let backgroundImage = album.isFilm
                ? `url('${album.image}')`
                : `url('${album.timelineImage}')`;
              let style = { left ,display};

              let cx = cn("album", { selected, unreleased, disabled , released , [bootLegItem] : bootLegItem });

              return (
                <div
                  key={idx}
                  className={cx }
                  data-id={album.id}
                  onClick={showInfo}
                  onMouseEnter={undefined /*disabled && this.mouseEnterItem*/}
                  onMouseLeave={undefined /*disabled && this.mouseLeaveItem*/}
                  style={style}
                >
                  {selected ? (
                    <BubbleInfo 
                      side={side}
                      year ={year}
                      spacing="110%"
                      onClose={this.closeAlbum}
                      object={album}
                      highlightedAlbumId={-1}
                      onView={this.clickViewAlbum}
                      onTracks={this.clickShowTracks}
                    />
                  ) : null}
                  <div className={`bg ${released}`}  style={{ backgroundImage }} >
                    {/* <div className="releaseLabel" style={{backgroundImage : `url(${releaseLabel})`}}></div> */}
                  </div>
                </div>
              );
            })
          : null}
        <TrackDots year={year} onClick={this.zoomToDate} />
        <TimelineEvents
          year={year}
          selectedEventId={selectedEventId}
          onClick={this.clickOnEvent}
          onClose={this.hideBubbleAndTracks}
          showMoreInfo={this.showEventModal}
          showVideo={this.showVideoModal}
          showPhotos={this.showPhotoModal}
        />
      </div>
    );
  }
  getCenteredYearAndMonth() {
    let { position } = this.state;
    let count = Pages.getVisiblePageCount(allYears, pageHeightToWidth);
    let centeringAdjustment = (count / 2) * pagePercentage - pagePercentage;

    position = position + centeringAdjustment;

    let year = currentYear(position).year;
    let month = currentMonth(position);

    return { year, month };
  }
  handleZoom() {
    this.zoomToDate(this.getCenteredYearAndMonth());
  }
  zoomToDate(query) {
    let router = this.context.router;
    let pathname = "/timeline-months";
    let location = router.createLocation({ pathname, query });
    delete location.search;
    router.push(location);
  }
  // scroller for other button feature
  scrollByPagePercentage(pct) {
    let { position } = this.state;
    position += pct * pagePercentage;
    position = clamp(position, getMinPercent(), getMaxPercent());
    this.setState({ position });
  }

  toggleUpdate(type){
    const {toggles} = this.props
    const toggleState = toggles[type]
    updateToggle({type, status: toggleState})
  }
  render() {
    let min = getMinPercent();
    let max = getMaxPercent();

    let { position, eventForModal, photoModalState, hovering} = this.state;
    const { toggles:{ original = false , bootleg = false , completion = false, additional= false }} = this.props
    let centeredYear = this.getCenteredYearAndMonth().year;

    let width = 720;
    let height = 980;
    let pageHeightToWidth = width / height;

    return (
      <div id="timeline" className={cn("years", { hovering })}>
        <div className="pages-wrapper">
          <Pages
            pct={position}
            scrollByPagePercentage={this.scrollByPagePercentage}
            pixelScrollEnded={this.updatePositionUrl}
            pageHeightToWidth={pageHeightToWidth}
            pageWidth={width}
            pageHeight={height}
            items={allYears}
            getPageContents={this.getPageContents}
            selectedAlbumId={this.state.selectedAlbumId}
            currentItemId={this.state.selectedEventId}
            onAlbumSelect={this.clickAlbum}
            sliderMin={min}
            sliderMax={max}
            sliderOnScroll={this.onScroll}
            sliderOnEnd={this.updatePositionUrl}
            original={original}
            additional={additional}
            bootLeg = {bootleg}
            completion ={completion}
            hideBubbleAndTracks={this.hideBubbleAndTracks}
            animateTo={this.animateTo}
            updateYearUrl={this.updateYearUrl}
            pagePercentage={pagePercentage}
          />
        </div>
        <div className="top-bar-shadow" />
        <div className="bottom-bar" />
        <div className="years-display-container">
          <div className="years-display-wrapper">
            <YearsDisplayHorizontal
              year={centeredYear}
              onClick={this.onYearClick}
            />
          </div>
        </div>
        <Toggles 
          original={original}
          additional={additional}
          bootLeg = {bootleg}
          completion={completion}
          toggleUpdate = {this.toggleUpdate}
        />
        <div className="zoom-button" onClick={this.handleZoom}></div>
        {eventForModal && (
          <LyricsModal
            track={eventForModal}
            content="info"
            markdown={true}
            onClose={this.hideEventModal}
          />
        )}
        {photoModalState && (
          <PhotoModal
            darkBackground={true}
            photo={photoModalState.current}
            collection={photoModalState.collection}
            location={this.props.location}
            onUpdate={this.photoModalOnUpdate}
            onDismiss={this.hidePhotoModal}
          />
        )}
      </div>
    );
  }
}

TimelineYearsContainer.contextTypes = {
  router: PropTypes.object.isRequired
};

const mapStateToProps = function (state) {
  if (state.status !== "drawerLoaded") {
      return {
          loaded: false,
      }
  }

  return { loaded: true , toggles: state.toggle};
};

const TimelineYearsWrapper = (props) => {
  if (!props.loaded) return <div id="timeline"><SplashScreen loadState={100} /></div>;
  return <TimelineYearsContainer {...props}/>
}

export default connect(mapStateToProps)(TimelineYearsWrapper);
