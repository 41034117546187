import _ from "lodash";
import React, { Component } from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import cn from "classnames";
import UserInfo from "./components/user-info";
import { getCurrentDateAsQueryString } from "./currentDate";
import { isFreeUser } from "./user";
import { connect } from "react-redux";
import { hasAuth } from "./services/api";

const q = (s) => () => `${s}?${getCurrentDateAsQueryString()}`;

//List of routes by columns
const menuColumns =[[
    {
      className: "gifts",
      text: "Giftcard",
    },
    { type: "delimiter" },
    { path: "/news/3", className: "sub-news", text: "Subscriber News" },
    { path: "album?id=X", className: "aow", text: "Album of the Week" },
    { path: "/info-card?track=X", className: "sod", text: "Song of the Day" },
  ],
  [
    { path: "/search?fromMenu=1", className: "find", text: "Find" },
    { path: "/welcome", className: "home", text: "Home" },
    { path: q("/drawer"), className: "file-cabinet", text: "File Cabinet" },
    { path: q("/timeline"), className: "timeline", text: "Timeline" },
    { path: "/drawer/?position=100", className: "playlist", text: "Playlist" },
    { type: "delimiter" },
    {
      path: "/movie-night/movietone",
      className: "pay-per-view-menu",
      text: "Movietone",
    },
    {
      path: "/movie-night/home",
      className: "movie-night-image",
      text: "Hearse Theater",
    },
    { path: "/news/1", className: "news", text: "NYA Times-Contrarian" },
    { type: "delimiter" },
    { url: "https://neilyoung.warnerrecords.com", className: "greedy-store", text: "Greedy Hand Store" },
    { url: "https://store.neilyoungarchives.com", className: "hi-res-store", text: "Hi-Res Store" },
  ],
  [
    { type: "delimiter" },
    { path: "/account?screen=tickets", className: "tickets", text: "Tickets" },
    { type: "delimiter" },
    { path: "/audio-setup", className: "audio-setup", text: "Audio Setup" },
    { path: "/faq", className: "faq", text: "FAQ" },
    { path: "/contact", className: "contact", text: "Contact Us" },
  ],
];
const links = [
  { path: "/search?fromMenu=1", className: "find" },
  { path: "/", className: "home" },
  { path: "/welcome", className: "home" },
  { path: q("/drawer"), className: "file-cabinet" },
  { path: q("/timeline"), className: "timeline" },
  { path: "/drawer/?position=100", className: "playlist" },
  { type: "delimiter" },
  { path: "/news/1", className: "news" },
  { path: "/movie-night/movietone", className: "pay-per-view-menu" },
  { path: "/movie-night/home", className: "movie-night-image" },
  { type: "delimiter" },
  { path: "/audio-setup", className: "audio-setup" },
  { path: "/faq", className: "faq" },
  { path: "/contact", className: "contact" },
  { type: "delimiter" },
];

const ignore = (e) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  return false;
};

class GlobalMenu extends Component {
  constructor(props) {
    super(props);

    let hasSigned = localStorage.getItem(
      "nya-user-has-signed-up-for-newsletter"
    );

    this.state = { ready: false, hasSigned, showingPulldown: false };

    this.onClose = this.onClose.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.onBGClick = this.onBGClick.bind(this);
    this.onNewsletterClick = this.onNewsletterClick.bind(this);
  }
  go(path) {
    let { router } = this.context;
    /* router.replace(router.createLocation(path)) */
    router.push(router.createLocation(path));
    localStorage.removeItem("searchedQuery");
  }
  onNewsletterClick() {
    this.go("/newsletter");
  }
  onClose() {
    let { router } = this.context;
    router.goBack();
  }
  escFunction(event) {
    if (event.keyCode === 27) {
      let { router } = this.context;
      router.goBack();
    }
  }
  onBGClick(e) {
    if (
      !e.target.closest(".user-info") &&
      !e.target.closest(".sharing") &&
      !e.target.closest(".drawer-front-bottom-items") &&
      !e.target.closest(".link")
    ) {
      this.props.router.goBack();
    }
  }
  renderColumnList(column){
    const { freeAlbums, freeTracks, globalMenuNewUrl = '4' } = this.props.commonValues;
    const columnItems = []

    column.forEach((link,index)=>{
      const {type, text, path:pathUrl =false, className, url = false } = link
      if (type === "delimiter") {
        columnItems.push( <div key={`delimiter-${index}`} className="delimiter" />);
      }else{
        let path = _.isFunction(pathUrl) ? link.path() : pathUrl;

        if( path && path.includes('X')){

        const getId = (tracks=>{
             let track = {};
             Object.keys(tracks).forEach(id=>{
               let key = tracks[id];
               track =  key && key.id;
             })
             return track;
          })
          //get album or track id
          let id =
            className === "sod" && freeTracks
              ? getId(freeTracks)
              : getId(freeAlbums) || false;
          if(id) path = path.replace('X',id)
        }

        let cx = cn("link", { [className]: true });

        let click = () => {
          if(url){
            window.open(url, "_blank");
            return;
          }
          if(path) this.go(path);
        };
        if (className === "playlist") {
          const playlist = this.props["playlists"].all || [];
          //Get first default playlist
          const defaultPlaylist = playlist.find(
            (item) => item && item.order === 1
          );
          const playlistId = (defaultPlaylist && defaultPlaylist.id) || "P_002";
          path = `/playlist?id=${playlistId}`;
        }

        if (className === "gifts") {
          path = '/account?screen=gift'
        }

        columnItems.push(<div onClick={click} key={path} className={cx} >{text}</div>)
      }

    })
    return <>{columnItems}</>
  }
  getLinks() {

    const columns = []
    menuColumns.forEach((column,id)=>{
      //TODO - get the column number in the meedle
      let styleName = id === 1 ? "column center" : "column"
      columns.push(<div key ={id} className={hasAuth() ? `${styleName} logged-in`: styleName}>{this.renderColumnList(column)}</div>)
    })
    return<>{columns}</>
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ ready: true });
    }, 50);
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  renderContent() {
    let { hasSigned } = this.state;
    const orastreamLink =
      process.env.ORASTREAMLINK || "https://store.neilyoungarchives.com";
    let c = String.fromCharCode(169); //copyright symbol
    let releaseVersion = process.env.RELEASE_VERSION;

    return (
      <div className="global-menu" onClick={this.onBGClick} key="global-menu">
        {this.props.readyToRenderUserInfo && (
          <UserInfo showSubscribe={isFreeUser()} />
        )}
        <div className="text-items">
          {this.getLinks()}
        </div>
        <div className="drawer-front-bottom-items">
          <div className="items-right">
            <p className="noHighlight"><span>{c}</span>2017 Shakey Pictures/
            <span>{c}</span>2017 Warner Records</p>
            <span className="pipe">|</span>
            <a target="_blank" onContextMenu={ignore} href={orastreamLink}>
              powered by ORASTREAM
            </a>
            <span className="pipe">|</span>
            <a target="_blank" onContextMenu={ignore} href="/terms.html">
              TERMS AND CONDITIONS
            </a>
            <span className="pipe">|</span>
            <a target="_blank" onContextMenu={ignore} href="/privacy.html">
              PRIVACY POLICY
            </a>
            <span className="pipe">|</span>
            <a
              target="_blank"
              onContextMenu={ignore}
              href="/privacy.html#adchoices"
            >
              AD CHOICES
            </a>
            <span className="pipe">|</span>
            <Link to="/credits">CREDITS</Link>
            <span className="pipe">|</span>
            <Link to="/faq">FAQ</Link>
            <span className="pipe">|</span>
            <p className="noHighlight">V. {releaseVersion}</p>
          </div>
        </div>
      </div>
    );
  }
  render() {
    let transitionClass = "global-menu-fade-in";
    let { ready } = this.state;

    return (
      <div id="global-menu-wrapper">
        <ReactCSSTransitionGroup
          component="div"
          transitionName={transitionClass}
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
        >
          {ready ? this.renderContent() : <div key="dummy"></div>}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}
GlobalMenu.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = function (state) {
  console.log("state", state);
  return {
    readyToRenderUserInfo: state.userData,
    playlists: state.playlists,
    commonValues: state.commonValues,
  };
};

export default connect(mapStateToProps)(GlobalMenu);
