import React, {Component} from 'react'
import SectionPage        from './components/section-page'
import store              from './store'
import marked             from 'marked'
import creditsImg from '../images/section-page/credits.png'
import { resetMetadata } from './actions/metas'

export default class CreditsContainer extends Component {
    componentWillMount(){
        resetMetadata()
    }
    getContent(){
        return store.getState().pages.byId.credits.content
    }
    render(){
        return (
            <SectionPage className="credits-page"
                         headerImageUrl={creditsImg}
                         html={marked(this.getContent())} />
        )
    }
}
