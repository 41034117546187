import _ from 'lodash'
import Audio from './audio'
import {getListedTracks, getFreeAlbumID, getAllSortedTracks} from '../../drawer-items'
import {isFreeUser, hasNotUnlimitedAccess} from '../../user'
import { loadPlayingTrack } from '../../actions/tracks'

let currentPlaylist = null
let currentIndex = null
let listeners = []
let isEmbed   = false;

const itemsForUsersWithUnlimitedAccess = (items) => {
    return items.filter(item => {
        let {album = {}} = item;
        return !hasNotUnlimitedAccess(item.excludeFreeAndClassicSubscription || (album && album.excludeFreeAndClassicSubscription));
    })
}
const showUnlimitedAccessMessage = ({excludeFreeAndClassicSubscription = false, album = {}}) => {
    if(hasNotUnlimitedAccess(excludeFreeAndClassicSubscription || (album && album.excludeFreeAndClassicSubscription)))
        window.unlimitedAccedV2();
}
class Manager {
    constructor(){
        this.onNext = this.onNext.bind(this)
        Audio.onTrackSelect(this.onNext)
    }
    onNext(idx) {
        currentIndex = idx
        const state = (idx === -1) ? 'STOPPED' : 'WILLPLAY'

        if (state === 'STOPPED') {
            for (let cb of listeners) cb({state})
            return
        }

        let atStart = idx === 0
        let atEnd   = idx === currentPlaylist.length - 1
        let track = currentPlaylist[idx]
        for (let cb of listeners) cb({state, track, atStart, atEnd})
    }
    addListener(listener) {
        listeners.push(listener)
    }
    removeListener(listener){
        listeners = listeners.filter(it=>it!==listener)
    }
    prevNextDisabled(){
        let idx = currentIndex
        if (!currentPlaylist || idx === null || idx === -1) {
            return {prevDisabled:true, nextDisabled:true}
        }
        let atStart = idx === 0
        let atEnd   = idx === currentPlaylist.length - 1
        return {prevDisabled:atStart, nextDisabled:atEnd}
    }
    currentTrack(){
        if (currentIndex === null || currentPlaylist === null) return null
        return currentPlaylist[currentIndex]
    }
    currentPlaylist(){
        if(currentPlaylist === null) return null;
        return currentPlaylist
    }
    playTrack(track) {
        this._playItemInItems(track, [track])
    }
    _filterItems(tracks, embed){
        let items = _.filter(tracks, 'orastream');
        if (isFreeUser() && !embed) items = _.filter(items, 'free');
        return items
    }
    playFromTrackChronologically(track) {
        this._playItemInItems(track, this._filterItems(getListedTracks()))
    }
    playTrackInMiddle(track, showAreYouStillListeningModal) {
        const tracks = this._filterItems(getListedTracks())
        this._playItemInMiddleItems(track, tracks, null, showAreYouStillListeningModal)
    }
    isShowAreYouStillListeningModal() {
        return Audio.isShowAreYouStillListeningModal()
    }
    isPrevOrNext() {
        return Audio.isPrevOrNext()
    }
    play() {
        Audio.play()
    }
    pause() {
        Audio.pause()
    }
    stopPlaying(){
        Audio.stopPlaying()
    }
    playFromTrackOnAlbum(track, album, field="tracks", embed) {
        let trackList = album[field] || []
        if(track && track.outTake) trackList['outtakeTracks']
        if(embed) isEmbed = embed;

        let idx = trackList.indexOf(track)
        // We validate the fields to be tracks because it's no longer only the album.tracks field that contains the list.
        if (idx < 9 && album.id && album.id.toLowerCase() === 'a_012' && field === 'tracks') {
            //skip the last two tracks for Harvest per N request
            let items = this._filterItems(album.tracks)
            items.pop()
            items.pop()
            this._playItemInMiddleItems(track, items)
        } else if(!track.demo && !track.outTake && album.type === 'album') {
            const allTracks = getAllSortedTracks();
            this._playItemInMiddleItems(track, this._filterItems(allTracks), album)
        } else {
            this._playItemInMiddleItems(track, this._filterItems(trackList), album)
        }
    }
    playAlbum(album, embed){
        this.pause();
        let items = this._filterItems(album.tracks, embed)

        if (!items.length) return;
        if(embed) isEmbed = embed ;
        if (album.id && album.id.toLowerCase() === 'a_012') {
            //skip the last two tracks for Harvest per N request
            // items.pop()
            // items.pop()
        }
        if (album.type === "album") {
            const allTracks = getAllSortedTracks();
            this._playItemInMiddleItems(items[0], this._filterItems(allTracks), album);
            this.play();
            return
        }
        this._playItemInItems(items[0], items, album)
        this.play();
    }
    resumePlayback(isInitial, isMp3=false){
        if (!this._playlists) return
        let next = this._playlists.shift()
        if (next) {
            currentPlaylist = next
            currentIndex    = 0
            if(!isMp3)Audio.playItems(next, 0, isInitial)
            this.onNext(0)
        }
    }
    _playItemInItems(track, items, album, showAreYouStillListeningModal) {
        Audio.setShowAreYouStillListeningModal(showAreYouStillListeningModal)
        loadPlayingTrack(track)
        showUnlimitedAccessMessage(track)
        let { id, mp3audio} = track
        let idx = _.findIndex(items, (item) => { return item.id === id })

        if (idx !== -1) {
            // Remove current track being played from items to play (track parameter)
            // And remove tracks that has the flag excludeFreeAndClassicSubscription=true
            const itemsToPlay = itemsForUsersWithUnlimitedAccess(items.slice(idx));
            // This section segments current items in individual playlists just for free users
            // Everytime the album played is not the free album (isFreeAlbum)
            // Later inside OnNext() the prompt for keep playing is showed after a playlist is finished
            const isFreeAlbum = album && album.id === getFreeAlbumID()
            let allowedPlaylistLength = isFreeUser() && !isFreeAlbum ? 1 : items.length;
            //TODO : test it for free user
            if(isEmbed){
                allowedPlaylistLength = items.length;
            }
            this._playlists = _.chunk(itemsToPlay, allowedPlaylistLength)
            this.resumePlayback(true, mp3audio)
        }
    }
    _playItemInMiddleItems(track, items, album, showAreYouStillListeningModal) {
        if (!items) return
        Audio.setShowAreYouStillListeningModal(showAreYouStillListeningModal)
        showUnlimitedAccessMessage(track)

        let { id } = track
        // update track on redux state
        loadPlayingTrack(track)
        let idx = _.findIndex(items, (item) => {return item.id === id})
        if(!track?.demo && !track?.outTake && album?.type === 'album') {
            const trackOrderId = `${album.id}_${track.id}`
            idx = _.findIndex(items, (item) => {return item.trackOrderId === trackOrderId})
        }
        if (idx !== -1) {
            const freeTracks = items.filter(item => item.free)

            const itemsToPlay = isFreeUser() ? freeTracks : itemsForUsersWithUnlimitedAccess(items)
            if (_.isElement(itemsToPlay)) return

            let playableIdx = _.findIndex(itemsToPlay, item => item.id === id)
            if(!track?.demo && !track?.outTake && album?.type === 'album') {
                const trackOrderId = `${album.id}_${track.id}`
                playableIdx = _.findIndex(items, (item) => {return item.trackOrderId === trackOrderId})
            }
            if (playableIdx === -1) return

            this._playlists = [itemsToPlay]
            currentPlaylist = itemsToPlay
            currentIndex    = playableIdx
            Audio.playItems(itemsToPlay, playableIdx, false)
            this.onNext(playableIdx)
        }
    }
}
export default new Manager()
