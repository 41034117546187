function pick(array){
    return array[Math.floor(Math.random() * array.length)]
}

function pick_not(array, exclude){
    let chosen = pick(array)
    while (chosen === exclude) chosen = pick(array)
    return chosen
}

function getMaxYear() {
    try {
        // add one extra year for margin
        return new Date().getFullYear() * 1 + 1
    } catch (error) {
        return 2022
    }
}

let colors    = ['#333', '#3b3b3b', '#222', '#2b2b2b']
export const minYear = 1959
export const maxYear = getMaxYear()

function genItems(){
    let result = []
    let count  = 0
    let color = pick(colors)
    let year = minYear
    while(year <= maxYear){
        let name = year
        color = pick_not(colors,color)
        result.push({
            id:`${count}`,
            color, year, name
        })
        year++
        count++
    }
    return result
}

export const allYears = genItems()
